@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';

.block-reference-section-wrap {
  padding: 64px 0;
  background-image: url('./../../assets/img/abstract-white-wave.png');
  background-repeat: repeat-x;
  background-position: bottom center;
  overflow: hidden;
  .splide__track {
    overflow: initial;
  }
  .content-wrap {
    margin-bottom: 48px;
  }
  .reference1-items-wrap {
    margin-bottom: 20px;
  }
  .reference2-items-wrap {
    margin-bottom: 20px;
  }
  .reference-item {
    position: relative;
    cursor: pointer;
    .image-wrap {
      filter: drop-shadow(0px 5px 15px rgba(68, 170, 0, 0.25));
      border-radius: 10px;  
      overflow: hidden;
      width: 220px;
      height: 128px;
      img {
        @include objetfit;
      }
    }
    .reference-content {
      position: absolute;
      opacity: 0;
      @include absolutefull;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 24px;
      transition:  $transition-base;
      i {
        font-size: toRem(24);
        margin-right: 4px;
        color: $white;
      }
      .title {
        color: $white;
        font-size: toRem(22);
        font-weight: 500;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
      }
      .vykon {
        display: flex;
        align-items: center;
        color: $white;
        font-size: toRem(18);
      }
    }
    &:hover {
      .reference-content {
        opacity: 1;
      }
    }
  }
  .button-wrap {
    margin-top: 48px;
  }
  @include media-breakpoint-up(lg) {
    padding: 96px 0 96px;
    /* .splide__slide {
      width: 300px;
    } */
    .reference-item {
      .image-wrap {
        width: 300px;
        height: 160px;
      }
    }
  }
}